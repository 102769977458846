export const getEmpresaDTO = (data = {}) => {
  return {
    codigoEmp: data.codigo_emp || null,
    codmatEmp: data.codmat_emp || null,
    codmunEmp: data.codmun_emp || null,
    codufEmp: data.coduf_emp || null,
    codcreEmp: data.codcre_emp || null,
    nomrazEmp: data.nomraz_emp || null,
    nomfanEmp: data.nomfan_emp || null,
    ativoEmp: data.ativo_emp || false, // manter false para que as novas empresas fiquem inativas para evitar cobranças indevidas
    bloqfinEmp: data.bloqfin_emp || false,
    empfatEmp: data.empfat_emp || null,
    empfatautEmp: data.empfataut_emp || undefined,
    datdesEmp: (data?.datdes_emp != null ? new Date(data?.datdes_emp) : null),
    cnpjEmp: data.cnpj_emp || null,
    emailEmp: data.email_emp || null,
    telcomEmp: data.telcom_emp || null,
    cepEmp: data.cep_emp || null,
    bairroEmp: data.bairro_emp || null,
    endEmp: data.end_emp || null,
    numendEmp: data.numend_emp || null,
    comendEmp: data.comend_emp || null,
    dahinsEmp: data.dahins_emp || null,
    dahultEmp: data.dahult_emp || null,
    dahdelEmp: data.dahdel_emp || null,
    cerpemEmp: data.cerpem_emp || null,
    cerkeyEmp: data.cerkey_emp || null,
    usuinsEmp: data.usuinsemp || null,
    usuultEmp: data.usuult_emp || null,
    usudelEmp: data.usudel_emp || null,
    perUsaDespEmp: data.perusadesp_emp || undefined,
    perNovDespEmp: data.pernovdesp_emp || undefined,
    coddespEmp: data.coddesp_emp,
    despachante: { codigo_emp: data.coddesp_emp } || null,
    nomresfinEmp: data.nomresfin_emp || null,
    emaresfinEmp: data.emaresfin_emp || null,
    telresfinEmp: data.telresfin_emp || null,
    termoaceEmp: data.termoace_emp || false,
    dahaceterEmp: data.dahaceter_emp || null,
    usuaceterEmp: data.usuaceter_emp || null
  }
}

export const postEmpresaDTO = (data = {}) => {
  return {
    codigo_emp: data.codigoEmp || null,
    codmat_emp: data.codmatEmp || null,
    codmun_emp: data.codmunEmp || null,
    coduf_emp: data.codufEmp || null,
    codcre_emp: data.codcreEmp || null,
    nomraz_emp: data.nomrazEmp || null,
    nomfan_emp: data.nomfanEmp || null,
    ativo_emp: data.ativoEmp || false,
    bloqfin_emp: data.bloqfinEmp || false,
    empfat_emp: data.empfatEmp || null,
    empfataut_emp: data.empfatautEmp || undefined,
    //datdes_emp: data.datdesEmp ? formatDateTimeSQL(data.datdesEmp) : null,
    cnpj_emp: data.cnpjEmp || null,
    email_emp: data.emailEmp || null,
    telcom_emp: data.telcomEmp || null,
    cep_emp: data.cepEmp || null,
    bairro_emp: data.bairroEmp || null,
    end_emp: data.endEmp || null,
    numend_emp: data.numendEmp || '',
    comend_emp: data.comendEmp || '',
    dahins_emp: data.dahinsEmp || null,
    dahult_emp: data.dahultEmp || null,
    dahdel_emp: data.dahdelEmp || null,
    cerpem_emp: data.cerpemEmp || null,
    cerkey_emp: data.cerkeyEmp || null,
    usuins_emp: data.usuinsEmp || null,
    usuult_emp: data.usuultEmp || null,
    usudel_emp: data.usudelEmp || null,
    perusadesp_emp: data.perUsaDespEmp || false,
    pernovdesp_emp: data.perNovDespEmp || false,
    coddesp_emp: data.despachante?.codigo_emp || null,
    nomresfin_emp: data.nomresfinEmp || '',
    emaresfin_emp: data.emaresfinEmp || '',
    telresfin_emp: data.telresfinEmp || '',
    termoace_emp: data.termoaceEmp || false,
    dahaceter_emp: data.dahaceterEmp || null,
    usuaceter_emp: data.usuaceterEmp || null
  }
}
