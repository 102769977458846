import { Accordion, AccordionTab } from 'primereact/accordion'
import React, { Component } from 'react'

class AccordionContainer extends Component {
  constructor (props) {
    super(props)

    this.state = {
      activeIndex: []
    }
  }

  componentDidMount () {
    let { activeIndex } = this.state

    React.Children.forEach(this.props.children, (child, index) => {
      if (child !== null && child.props.active)
        activeIndex.push(index)
    })

    this.setState({ activeIndex })
  }

  headerTemplate = (headerTitle, indexAccordionPage) => {
    const toggleIcon = !this.state.activeIndex.some((indexAtivo) => indexAtivo === indexAccordionPage) ? 'pi pi-angle-double-down' : 'pi pi-angle-double-up'

    return (
      <div className="header">
        <span>{headerTitle}</span>
        <i className={toggleIcon}></i>
      </div>
    )
  }

  render () {
    const tabs = React.Children.map(this.props.children, (child, indexAccordionPage) => {
      if (child !== null) {
        return (
          <AccordionTab key={indexAccordionPage} header={this.headerTemplate(child.props.header, indexAccordionPage)} disabled={child.props.disabled}>
            {child.props.children}
          </AccordionTab>
        )
      }
    })

    return (
      <Accordion
        multiple
        className={(React.Children.count(this.props.children) === 1 ? 'unique col-12' : 'many col-12') + ` ${this.props.className || ''}`}
        activeIndex={this.state.activeIndex}
        onTabChange={(e) => this.setState({ activeIndex: e.index })}
      >
        {tabs}
      </Accordion>
    )
  }
}

export default AccordionContainer
