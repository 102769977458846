import { Fieldset } from 'primereact/fieldset'
import { classNames } from 'primereact/utils'
import React from 'react'
import ReactInputMask from 'react-input-mask'
import TextAreaInput from '../../../../../../components/inputs/TextAreaInput'
import TextInput from '../../../../../../components/inputs/TextInput'
import FieldErrorMessage from '../../../../../../components/utils/FieldErrorMessage'
import RequiredLabel from '../../../../../../components/utils/RequiredLabel'
import { formatCurrencyToDecimal, formatToCurrency } from '../../../../../../helpers/formaters'
import { useValidateInput } from '../../../../../../helpers/useValidateInput'

const DadosPrincipaisPcr = ({ form, edicaoHabilitada }) => {
  const { isFormFieldValid, getFormErrorMessage } = useValidateInput(form)

  const setFieldTouched = (e) => form.setFieldTouched(e.target.name)

  const handleChangeMask = (ev) => {
    setCPFCNPJ(ev)
    let { value } = ev.target
    let nums = value?.replace(/[^0-9]/g, '')
    if ((nums.length < 3) || (nums.length === 11 && isEmpDesp)) {
      ev.target.value = nums
    }
    form.handleChange(ev)
  }

  const setCPFCNPJ = (ev) => {
    let { value } = ev.target
    let nums = value?.replace(/[^0-9]/g, '')

    ev.target.value = nums
    setFieldTouched(ev)
  }

  return (
    <div className="formgrid grid">

      {/* FORM */}

      <div className="field col-12 md:col-6">
        <RequiredLabel label={'CNPJ'} valid={isFormFieldValid('cnpj_pcr')}/>
        <ReactInputMask
          placeholder='Insira o CNPJ'
          mask={'99.999.999/9999-99'}
          maskChar="_"
          name="cnpj_pcr"
          value={form.values.cnpj_pcr}
          onChange={handleChangeMask}
          disabled={!edicaoHabilitada}
          className={classNames({ 'p-invalid': isFormFieldValid('cnpj_pcr') }, 'p-inputtext p-component p-filled inputfield w-full')}
          onBlur={setCPFCNPJ}
        />
        <FieldErrorMessage message={getFormErrorMessage('cnpj_pcr')}/>
      </div>
      <div className="field col-12 md:col-6">
        <RequiredLabel label="Nome/Razão Social" valid={isFormFieldValid('nomraz_pcr')}/>
        <TextInput
          placeholder="Insira o nome/razão social"
          value={form.values.nomraz_pcr}
          name="nomraz_pcr"
          onChange={form.handleChange}
          className={classNames({ 'p-invalid': isFormFieldValid('nomraz_pcr') }, 'inputfield w-full')}
          onBlur={setFieldTouched}
          disabled={!edicaoHabilitada}
        />
        <FieldErrorMessage message={getFormErrorMessage('nomraz_pcr')}/>
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="E-mail"
          placeholder="Insira o e-mail"
          value={form.values.email_pcr}
          name="email_pcr"
          onChange={form.handleChange}
          className={'inputfield w-full'}
          disabled={!edicaoHabilitada}
        />
      </div>
      <div className="field col-12 md:col-6">
        <TextInput
          label="Telefone"
          placeholder="Insira o telefone"
          name="tel_pcr"
          value={form.values.tel_pcr}
          onChange={form.handleChange}
          mask="(99) 99999-999?9"
          disabled={!edicaoHabilitada}
          className="inputfield w-full"
        />
      </div>

      {/* VALORES DE REPASSE */}

      <div className="field col-12">
        <Fieldset
          legend="Valor de repasse para o parceiro"
          className='custom-fieldset'
        >
          <div className="field col-12 md:col-6">
            <RequiredLabel
              label="Valor parceria usados"
              valid={isFormFieldValid('valusa_pcr')}
            />
            <TextInput
              name="valusa_pcr"
              placeholder="Valor parceria usados"
              className={classNames({ 'p-invalid': isFormFieldValid('valusa_pcr') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valusa_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
            <FieldErrorMessage message={getFormErrorMessage('valusa_pcr')}/>
          </div>
          <div className="field col-12 md:col-6">
            <RequiredLabel
              label="Valor parceria novos"
              valid={isFormFieldValid('valnov_pcr')}
            />
            <TextInput
              name="valnov_pcr"
              placeholder="Valor parceria novos"
              className={classNames({ 'p-invalid': isFormFieldValid('valnov_pcr') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valnov_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
            <FieldErrorMessage message={getFormErrorMessage('valnov_pcr')}/>
          </div>
          <div className="field col-12 md:col-6">
            <RequiredLabel
              label="Valor parceria ite"
              valid={isFormFieldValid('valite_pcr')}
            />
            <TextInput
              name="valite_pcr"
              placeholder="Valor parceria ite"
              className={classNames({ 'p-invalid': isFormFieldValid('valite_pcr') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valite_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
            <FieldErrorMessage message={getFormErrorMessage('valite_pcr')}/>
          </div>
          <div className="field col-12 md:col-6">
            <RequiredLabel
              label="Valor parceria montadora"
              valid={isFormFieldValid('valmot_pcr')}
            />
            <TextInput
              name="valmot_pcr"
              placeholder="Valor parceria montadora"
              className={classNames({ 'p-invalid': isFormFieldValid('valmot_pcr') }, 'inputfield w-full')}
              onBlur={setFieldTouched}
              disabled={!edicaoHabilitada}
              value={formatToCurrency(form.values.valmot_pcr)}
              onChange={(e) => {
                e.target.value = formatCurrencyToDecimal(e.target.value)
                form.handleChange(e)
              }}
            />
            <FieldErrorMessage message={getFormErrorMessage('valmot_pcr')}/>
          </div>
        </Fieldset>
      </div>

      {/* OBSERVAÇÕES */}

      <div className="field col-12">
        <TextAreaInput
          label="Observações financeiras"
          value={form.values.obsfin_pcr}
          name="obsfin_pcr"
          onChange={form.handleChange}
          className={'inputfield w-full'}
          disabled={!edicaoHabilitada}
          placeholder="Digite aqui..."
          rows={6}
        />
      </div>
    </div>
  )
}

export default DadosPrincipaisPcr
