import * as Yup from 'yup'

export const ParceiroValidator = () => {
  return Yup.object().shape({
    nomraz_pcr: Yup.string()
      .nullable()
      .required('O nome/razão social é obrigatório!'),
    cnpj_pcr: Yup.string()
      .nullable()
      .required('O CNPJ é obrigatório!'),
    valusa_pcr: Yup.number()
      .nullable()
      .required('O valor é obrigatório!'),
    valnov_pcr: Yup.number()
      .nullable()
      .required('O valor é obrigatório!'),
    valite_pcr: Yup.number()
      .nullable()
      .required('O valor é obrigatório!'),
    valmot_pcr: Yup.number()
      .nullable()
      .required('O valor é obrigatório!')

  })
}
