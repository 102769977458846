export const getParceiroDTO = (data = {}) => {
  return {
    codigo_pcr: data.codigo_pcr,
    cnpj_pcr: data.cnpj_pcr,
    nomraz_pcr: data.nomraz_pcr,
    email_pcr: data.email_pcr,
    tel_pcr: data.tel_pcr,
    obsfin_pcr: data.obsfin_pcr,
    valusa_pcr: data.valusa_pcr,
    valnov_pcr: data.valnov_pcr,
    valite_pcr: data.valite_pcr,
    valmot_pcr: data.valmot_pcr,
    empresa: data.empresa
  }
}

export const postParceiroDTO = (data = {}) => {
  return {
    codigo_pcr: data.codigo_pcr,
    cnpj_pcr: data.cnpj_pcr,
    nomraz_pcr: data.nomraz_pcr,
    email_pcr: data.email_pcr,
    tel_pcr: data.tel_pcr,
    obsfin_pcr: data.obsfin_pcr,
    valusa_pcr: data.valusa_pcr,
    valnov_pcr: data.valnov_pcr,
    valite_pcr: data.valite_pcr,
    valmot_pcr: data.valmot_pcr
  }
}
