import React from 'react'
import { Crud } from '../../../../classes/Crud'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import { getParceiroDTO } from '../../../../dtos/cadastro/ParceiroDTO'
import ParceiroForm from '../../../../forms/cadastro/parceiro/main'
import ParceiroService from '../../../../services/cadastro/ParceiroService'

export default class Parceiro extends Crud {
  constructor (props) {
    super(props, getParceiroDTO, ParceiroService, 'parceiros')
  }

  render () {
    const { model, dataLoaded, isNew } = this.state
    return (
      <Page>
        <Container>
          {(dataLoaded || isNew) && (
            <ParceiroForm
              model={model}
              isNew={isNew}
              onDelete={this.onDelete}
            />
          )}
        </Container>
      </Page>
    )
  }
}
