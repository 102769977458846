import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import React from 'react'
import List from '../../../../classes/List'
import Container from '../../../../components/layout/Container'
import Page from '../../../../components/layout/Page'
import DataList from '../../../../components/utils/DataList'
import { getParceiroDTO } from '../../../../dtos/cadastro/ParceiroDTO'
import CommonHelper from '../../../../helpers/CommonHelper'
import ParceiroService from '../../../../services/cadastro/ParceiroService'

export default class ParceiroList extends List {
  constructor (props) {
    super(
      props,
      getParceiroDTO,
      ParceiroService,
      'parceiro',
      'codigo_pcr'
    )

    this.state = {
      ...this.state
    }

    this.setColumns([
      {
        key: 'codigo_pcr',
        component: (
          <Column
            key='codigo_pcr'
            className="p-col-2"
            field="codigo_pcr"
            header="Código"
            sortable
            headerClassName="codigo_pcr"
          />
        )
      },
      {
        key: 'nomraz_pcr',
        component: (
          <Column
            key='nomraz_pcr'
            className="p-col-8"
            field="nomraz_pcr"
            header="Nome Razão"
            sortable
            headerClassName="nomraz_pcr"
          />
        )
      },
      {
        key: 'cnpj_pcr',
        component: (
          <Column
            key='cnpj_pcr'
            className="p-col-2"
            field="cnpj_pcr"
            body={(data) => CommonHelper.formatDocument(data.cnpj_pcr)}
            header="CNPJ"
            sortable
            headerClassName="cnpj_pcr" />
        )
      }
    ])
  }

  render () {
    const { rows, totalRecords, first, page, list, selected } = this.state

    return (
      <Page>
        <div className="flex justify-content-between align-items-center page-header">
          <h4>Parceiros</h4>
          <div className="page-header-buttons">
            <Button
              className="mr-2"
              label={CommonHelper.isDesktop() && 'Novo'}
              icon="pi pi-plus"
              onClick={this.onNew}
            />
          </div>
        </div>
        <Container>
          <DataList
            data={list}
            selected={selected}
            rows={rows}
            onSelect={this.onSelect}
            totalRecords={totalRecords}
            first={first}
            onPage={this.onPageChange}
            page={page}
            onRowSelect={(e) => this.onView(e.data)}
            showFilter
            filterPlaceholder="Procurar parceiros"
            filterName="descricaoFiltro"
            responsive
            filterOnChange={this.handleChangeFilter}
            onFilter={this.onFilter}
          >
            {this.columns.map((column) => column.component)}
          </DataList>
        </Container>
      </Page>
    )
  }
}
