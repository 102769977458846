import { postParceiroDTO } from '../../../dtos/cadastro/ParceiroDTO'
import ServiceBase from '../../base/ServiceBase'

export default class ParceiroService {
  async get (id) {
    return await ServiceBase.getBase(`parceiro/${id}`)
  }

  async getAll (page, perPage) {
    return await ServiceBase.getBase(`parceiros?page=${page}&perPage=${perPage}`)
  }

  async filter (query, page, perPage) {
    return await ServiceBase.getBase(`parceiros?${query}&page=${page}&perPage=${perPage}`)
  }

  static async save (data) {
    return await ServiceBase.postBase('parceiro', postParceiroDTO(data))
  }

  async delete (id) {
    return await ServiceBase.deleteBase(`parceiro/${id}`)
  }
}
