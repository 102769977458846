import { Calendar } from 'primereact/calendar'
import React, { useRef } from 'react'
import CommonHelper from '../../helpers/CommonHelper'

const DateInput = (props) => {
  const calendarRef = useRef(null)

  return (
    <span>
      <label className="label" htmlFor={props.name}>{props.label}</label>
      <Calendar
        {...props}
        ref={calendarRef}
        value={props.value || ''}
        showIcon={props.showIcon || true}
        dateFormat="dd/mm/yy"
        mask="99/99/9999"
        locale="br"
        monthNavigator
        yearNavigator
        yearRange="1900:2100"
        hideOnDateTimeSelect
        readOnlyInput={!CommonHelper.isDesktop()}
      />
    </span>
  )
}

export default DateInput
